<template>
    <comp-form
        title="商品"
        ref="comp_form"
        :fullscreen="true"
        submit-api="/gateway/apps/o2o/api/o2o/goods/addGood"
        detail-api="/gateway/apps/o2o/api/o2o/goods/getGoodsDetail"
        edit-api="/gateway/apps/o2o/api/o2o/goods/modifyGoods"
        id-key="goodsId"
        :submit-before="onSubmitBefore"
        :data-before="onDataBefore"
        :data-watch="dataWatch"
        :menus="menus"
        @on-submit="onReset"
    >
        <template v-slot:form-box="data">
            <ElemMenuItem title="商品属性">
                <ElemFormItem title="供应商" name="supplierId" required width="120px">
                    <Select v-model="data.form.supplierId" filterable clearable style="width: 300px">
                        <Option v-for="item in suppliers" :value="item.supplierId" :key="item.supplierId">{{ item.supplierName }}</Option>
                    </Select>
                </ElemFormItem>
                <ElemFormItem title="商品服务类型" name="serviceType" required width="120px">
                    <RadioGroup v-model="data.form.serviceType">
                        <Radio label="goods">实物商品</Radio>
                        <Radio label="service">预约到店服务</Radio>
                        <Radio label="visit_service">预约上门服务</Radio>
                        <Radio label="coupon">到店核销券</Radio>
                        <Radio label="scope_qrcode">扫码点餐</Radio>
                    </RadioGroup>
                </ElemFormItem>
                <ElemFormItem title="条码货号" :display="data.form.serviceType === 'goods' || data.form.serviceType === 'scope_qrcode'" width="120px">
                    <Input style="width: 300px" maxlength="100" v-model="data.form.goodsSn" placeholder="请填写条码货号"></Input>
                </ElemFormItem>
                <ElemFormItem :title="getTypeName(data.form.serviceType) + '名称'" name="goodsName" required width="120px">
                    <Input style="width: 300px" maxlength="100" v-model="data.form.goodsName" placeholder="请填写名称"></Input>
                </ElemFormItem>
                <ElemFormItem title="排序" width="120px">
                    <Input style="width: 300px" type="number" maxlength="100" v-model="data.form.sortOrder" placeholder="排序值越小越靠前"></Input>
                </ElemFormItem>
                <ElemFormItem title="商品分类" name="goodsType" :display="!!data.form.supplierId" required width="120px">
                    <Select v-model="data.form.goodsType" filterable style="width: 300px">
                        <Option v-for="item in categorys" :value="item.catId" :key="item.catId">{{ item.catName }}</Option>
                    </Select>
                </ElemFormItem>
                <ElemFormItem :title="getTypeName(data.form.serviceType) + '图片'" name="goodsImg" required width="120px">
                    <ElemUpload :value="data.form.goodsImg" :concise="true" @on-change="data.form.goodsImg = $event.value"></ElemUpload>
                </ElemFormItem>
                <ElemFormItem :title="getTypeName(data.form.serviceType) + '相册'" width="120px">
                    <ElemUpload :multiple="true" :concise="true" :value="data.form.listImge" @on-change="data.form.listImge = $event.value"></ElemUpload>
                </ElemFormItem>
                <ElemFormItem title="简单描述" name="goodsBrief" width="120px">
                    <Input style="width: 300px" maxlength="50" type="textarea" v-model="data.form.goodsBrief" placeholder="请填写简单描述 (50个字以内)"></Input>
                </ElemFormItem>
                <!-- <ElemFormItem title="取货方式" :display="data.form.serviceType === 'goods'">
                    <Checkbox v-model="data.form.isShipping">可配送</Checkbox>
                    <Checkbox v-model="data.form.isExtract">可自提</Checkbox>
                </ElemFormItem> -->
            </ElemMenuItem>

            <ElemMenuItem title="详细描述">
                <iframe name="ueditor" :id="ueditor_id" src="/mobileeditor/#/index?titlename=详情内容" style="width: 100%; height: 700px; border: 0px"></iframe>
            </ElemMenuItem>

            <ElemMenuItem title="预约配置" :condition="v => v.serviceType && v.serviceType.indexOf('service') > -1">
                <ElemFormItem title="预约时间类型" name="timeType">
                    <RadioGroup v-model="data.form.timeType">
                        <Radio label="business">营业时间</Radio>
                        <Radio label="universal_period">通用时间段</Radio>
                    </RadioGroup>
                </ElemFormItem>
                <ElemFormItem
                    title="时间间隔"
                    name="timeInterval"
                    :display="(data.form.serviceType === 'service' || data.form.serviceType === 'visit_service') && data.form.timeType === 'universal_period'"
                >
                    <Select v-model="data.form.timeInterval" filterable style="width: 300px">
                        <Option v-for="(item, idx) in timeIntervals" :value="idx" :key="idx">{{ item }}</Option>
                    </Select>
                </ElemFormItem>
                <ElemFormItem title="预约上限" name="reserveLimit">
                    <Input
                        style="width: 300px"
                        maxlength="5"
                        type="number"
                        v-model="data.form.reserveLimit"
                        :placeholder="'请填写' + (data.form.timeType == 'business' ? '营业时间内' : '每个时间段') + '预约上限'"
                    ></Input>
                </ElemFormItem>
                <ElemFormItem title="市场售价" name="marketPrice">
                    <Input style="width: 300px" maxlength="5" type="number" v-model="data.form.marketPrice" placeholder="请填写市场售价 (单位：元)"></Input>
                </ElemFormItem>
                <ElemFormItem title="本店售价" name="shopPrice">
                    <Input style="width: 300px" maxlength="5" type="number" v-model="data.form.shopPrice" placeholder="请填写本店售价 (单位：元)"></Input>
                </ElemFormItem>
                <ElemFormItem title="促销售价">
                    <Input style="width: 300px" maxlength="5" type="number" v-model="data.form.promotePrice" placeholder="请填写促销售价 (设定促销时生效，单位：元)"></Input>
                </ElemFormItem>
            </ElemMenuItem>

            <ElemMenuItem title="规格配置" :condition="v => v.serviceType === 'goods' || v.serviceType === 'scope_qrcode'">
                <ElemFormList v-model="data.form.goodsSp" name="goodsSp" add-title="添加规格">
                    <template v-slot:formBox="list">
                        <ElemFormItem title="规格名称" name="spName" required>
                            <Input style="width: 300px" maxlength="20" v-model="list.item.spName" placeholder="请填写规格名称"></Input>
                        </ElemFormItem>
                        <ElemFormItem title="重量" name="weight" required>
                            <Input style="width: 300px" maxlength="20" v-model="list.item.weight" placeholder="请填写重量 (单位：g/克)"></Input>
                        </ElemFormItem>
                        <ElemFormItem title="市场售价" name="marketPrice">
                            <Input style="width: 300px" maxlength="5" type="number" v-model="list.item.marketPrice" placeholder="请填写市场售价 (单位：元)"></Input>
                        </ElemFormItem>
                        <ElemFormItem title="本店售价" name="shopPrice" required>
                            <Input style="width: 300px" maxlength="5" type="number" v-model="list.item.shopPrice" placeholder="请填写本店售价 (单位：元)"></Input>
                        </ElemFormItem>
                        <ElemFormItem title="促销售价">
                            <Input style="width: 300px" maxlength="5" type="number" v-model="list.item.promotePrice" placeholder="请填写促销售价 (设定促销时生效，单位：元)"></Input>
                        </ElemFormItem>
                        <ElemFormItem title="贡献值" name="contributionPrice" required>
                            <Input style="width: 300px" type="number" v-model="list.item.contributionPrice" placeholder="请填写贡献值 (单位：元)"></Input>
                        </ElemFormItem>
                        <ElemFormItem title="库存数量" name="goodsNumber" required>
                            <Input style="width: 300px" maxlength="8" type="number" v-model="list.item.goodsNumber" placeholder="请填写库存数量"></Input>
                        </ElemFormItem>
                    </template>
                </ElemFormList>
                <ElemFormItem title="库存报警下限" name="warnNumber" required>
                    <Input style="width: 300px" maxlength="5" type="number" v-model="data.form.warnNumber" placeholder="请填写库存报警下限 (最大值: 255)"></Input>
                </ElemFormItem>
            </ElemMenuItem>

            <ElemMenuItem title="规则配置" :condition="v => v.serviceType === 'coupon'">
                <ElemFormItem title="本店售价" name="couponPrice" required>
                    <Input style="width: 300px" type="number" v-model="data.form.couponPrice" placeholder="请填写本店售价 (单位：元)"></Input>
                </ElemFormItem>
                <ElemFormItem title="促销售价" name="promotePrice">
                    <Input style="width: 300px" type="number" v-model="data.form.promotePrice" placeholder="请填写促销售价 (设定促销时生效，单位：元)"></Input>
                </ElemFormItem>
                <ElemFormItem title="市场售价" name="marketPrice" required>
                    <Input style="width: 300px" type="number" v-model="data.form.marketPrice" placeholder="请填写市场售价 (单位：元)"></Input>
                </ElemFormItem>
                <ElemFormItem title="贡献值" name="contributionPrice">
                    <Input style="width: 300px" type="number" v-model="data.form.contributionPrice" placeholder="请填写贡献值 (单位：元)"></Input>
                </ElemFormItem>
                <ElemFormItem title="库存数量" name="couponNumber" required>
                    <Input style="width: 300px" maxlength="8" type="number" v-model="data.form.couponNumber" placeholder="请填写库存数量"></Input>
                </ElemFormItem>
                <ElemFormItem title="有效使用日期" name="validity" required>
                    <DatePicker v-model="data.form.validity" :options="datePickerOptions" type="daterange" placement="bottom-end" placeholder="请选择有效使用日期" style="width: 300px" />
                </ElemFormItem>
                <ElemFormItem title="使用时间" name="usageTimeType" required>
                    <RadioGroup v-model="data.form.usageTimeType">
                        <Radio label="business">营业时间</Radio>
                        <Radio label="custom">选择具体时间段</Radio>
                    </RadioGroup>
                </ElemFormItem>
                <ElemFormItem title="使用时间段" name="usageTime" :display="data.form.usageTimeType === 'custom'">
                    <TimePicker v-model="data.form.usageTime" type="timerange" placement="bottom-end" placeholder="请选择使用时间段" style="width: 300px" />
                </ElemFormItem>
                <ElemFormItem title="保障">
                    <Checkbox v-model="data.form.isUnconsumed">未消费随时退款</Checkbox>
                    <Checkbox v-model="data.form.isOutDate">过期未消费自动退款</Checkbox>
                </ElemFormItem>
                <ElemFormItem title="使用规则" name="description">
                    <Input style="width: 300px" maxlength="250" type="textarea" v-model="data.form.description" placeholder="请填写使用说明"></Input>
                </ElemFormItem>
            </ElemMenuItem>

            <ElemMenuItem title="活动促销">
                <ElemFormItem title="类型选择">
                    <Checkbox :value="data.form.isHot || false" @on-change="data.form.isHot = $event">热销</Checkbox>
                    <Checkbox v-model="data.form.isBest">推荐</Checkbox>
                    <Checkbox v-model="data.form.isPromote">促销</Checkbox>
                </ElemFormItem>
                <ElemFormItem title="促销时段" name="promoteDate" :display="!!data.form.isPromote">
                    <DatePicker v-model="data.form.promoteDate" type="daterange" placement="bottom-end" placeholder="请选择促销开始与结束日期" style="width: 300px" />
                </ElemFormItem>
            </ElemMenuItem>
        </template>
    </comp-form>
</template>

<script>
import CompForm from "../../jointly/components/CompForm.vue"
import ElemFormItem from "../../jointly/components/elem-form-item.vue"
import ElemFormList from "../../jointly/components/elem-form-list.vue"
import ElemMenuItem from "../../jointly/components/ElemMenuItem.vue"
import ElemUpload from "../../jointly/components/elem-upload.vue"
import Headway from "../../jointly/utils/headway"
import Utils from "../../jointly/utils/utils"

export default {
    components: { CompForm, ElemFormItem, ElemFormList, ElemMenuItem, ElemUpload },

    data() {
        return {
            // 商家列表
            suppliers: null,
            // 分类
            categorys: null,
            // 时间间隔
            timeIntervals: ["30分钟", "1小时", "1.5小时", "2小时", "2.5小时", "3小时"],
            // 富文本组件 ID
            ueditor_id: this.$core.randomString(),

            dataWatch: {
                supplierId: v => {
                    this.supplierId = v

                    this.getCategorys(v)
                },
            },

            // 日期选择器选项
            datePickerOptions: {
                disabledDate: date => {
                    // 判断日期是否大于等于今天，如果是则禁止选择
                    return date && date.valueOf() < new Date(this.$core.formatDate(new Date(), "yyyy-MM-dd 00:00:00")).valueOf()
                },
            },
        }
    },

    props: {
        supplierList: {
            type: Array,
            default: () => [],
        },
    },

    watch: {
        supplierList: {
            immediate: true,
            handler(v) {
                this.suppliers = v
            },
        },
    },

    methods: {
        getSupplierList() {
            this.$get("/gateway/apps/o2o/api/o2o/user/selectUserHasSupplier", {
                oemCode: parent.vue.oemInfo.oemCode,
            }).then(res => {
                if (res.code == 200) {
                    this.suppliers = res.dataList

                    if (this.suppliers.length == 0) {
                        this.$Modal.warning({ title: "温馨提示", content: "当前账号没有绑定供应商，请先前往绑定！" })
                    }
                }
            })
        },

        getCategorys(s) {
            if (!s) return

            this.$get("/gateway/apps/o2o/api/o2o/admin/getSupplierCategoryListBySupplierId", {
                oemCode: parent.vue.oemInfo.oemCode,
                supplierId: s,
            }).then(res => {
                if (res.code == 200) {
                    this.categorys = res.dataList
                }
            })
        },

        /**
         * 打开表单窗口
         */
        open(id, data) {
            // 置空富文本
            Headway.of(document.getElementById(this.ueditor_id).contentWindow, "setContent")
                .on()
                .then(f => {
                    f('<div id="editorGroup" style="position: relative; inset: 0px; max-width: 100%; padding: 10px; overflow: hidden; background-size: 100%; font-size: 16px;"></div>')
                })

            if (!this.suppliers?.length) {
                this.getSupplierList()
            }

            this.$refs.comp_form.open(id, data)
        },

        /**
         * 请求数据前置事件
         */
        onDataBefore(data) {
            // data.isExtract = data.isShipping == 3 || data.isShipping == 2
            // data.isShipping = data.isShipping == 3 || data.isShipping == 1

            // 写入富文本内容
            data.goodsDesc &&
                new Headway(document.getElementById(this.ueditor_id).contentWindow, "setContent").on().then(f => {
                    f(/^<div.*<\/div>$/.test(data.goodsDesc) ? data.goodsDesc : `<div>${data.goodsDesc}</div>`)
                })

            // 是否为服务类详情
            if (~data.serviceType.indexOf("service") && data.goodsPeriodSetVos) {
                // 处理时间段数据
                this.processPeriod(data, data.goodsPeriodSetVos)
                // 删除字段
                delete data.goodsPeriodSetVos
            }

            if (data.serviceType === "coupon") {
                data.ruleId = data.goodsRuleVo.ruleId
                data.validity = [new Date(data.goodsRuleVo.startDate), new Date(data.goodsRuleVo.endDate)]
                data.isUnconsumed = data.goodsRuleVo.guarantee.indexOf("1") > -1
                data.isOutDate = data.goodsRuleVo.guarantee.indexOf("2") > -1
                data.description = data.goodsRuleVo.description
                data.usageTimeType = data.goodsRuleVo.usageTime ? "custom" : "business"
                data.usageTime = data.goodsRuleVo.usageTime ? data.goodsRuleVo.usageTime.split("-").map(v => new Date(`1970-01-02 ${v}`)) : []
                data.couponPrice = data.shopPrice
                data.couponNumber = data.goodsNumber
            }

            data.isHot = data.isHot === 1
            data.isBest = data.isBest === 1
            data.isPromote = data.isPromote === 1

            // 处理促销时间
            if (data.isPromote && data.promoteStartDate && data.promoteEndDate) {
                if (!data.promotePrice || data.promotePrice === "0") {
                    this.$Message.warning("促销生效时，促销售价不能为空！")
                    return false
                }

                data.promoteDate = [new Date(data.promoteStartDate * 1000), new Date(data.promoteEndDate * 1000)]
            }


            return data
        },

        /**
         * 处理时间段
         */
        processPeriod(data, list) {
            if (list?.length > 0) {
                // 当时间段为一个时，默认为营业时间类型
                data.timeType = list.length === 1 ? "business" : "universal_period"
                // 获取第一个时间段的预约上限
                data.reserveLimit = list[0].num
                // 是否为时间段类型
                if (data.timeType === "universal_period") {
                    // 拆分时间段
                    var s = list[0].periodTime.split("-")
                    // 获取时间间隔
                    const interval = new Date(`1970-01-02 ${s[1]}`) - new Date(`1970-01-02 ${s[0]}`)
                    // 求 30 分钟的个数
                    data.timeInterval = interval / 1000 / 60 / 30 - 1
                }
            }
        },

        /**
         * 获取时间段
         */
        async getPeriod(data) {
            var { openStart, openEnd } = await this.getBusinessHours()

            if (!openStart || !openEnd) {
                this.$Message.error({
                    content: "当前店铺尚未设置营业时间，请先设置营业时间！",
                    background: true,
                })

                return false
            }

            if (data.timeType === "business") {
                return [
                    {
                        periodTime: `${this.$core.formatDate(new Date(openStart * 1000), "hh:mm")}-${this.$core.formatDate(new Date(openEnd * 1000), "hh:mm")}`,
                        num: Number(data.reserveLimit),
                    },
                ]
            }

            const res = []
            const interval = 30 * (data.timeInterval + 1) * 60
            var end = openStart

            while (openStart < openEnd) {
                end += interval

                res.push({
                    periodTime: `${this.$core.formatDate(new Date(openStart * 1000), "hh:mm")}-${this.$core.formatDate(new Date((end > openEnd ? openEnd : end) * 1000), "hh:mm")}`,
                    num: Number(data.reserveLimit),
                })

                openStart += interval
            }

            return res
        },

        /**
         * 重置事件
         */
        onReset() {
            this.$emit("on-reset")
        },

        /**
         * 提交数据前置事件
         */
        async onSubmitBefore(data) {
            console.log(data)

            var goodsNumber = 0

            Utils.each(data.goodsSp, v => {
                goodsNumber += parseInt(v.goodsNumber)
            })

            // 初始化海报图片、原始图片
            data.goodsThumb = data.originalImg = data.goodsImg
            
            if (data.serviceType === "goods" || data.serviceType === 'scope_qrcode') {
                
                
                // var isShipping = 0
                // data.isShipping && (isShipping += 1)
                // data.isExtract && (isShipping += 2)
                // data.isShipping = isShipping

                data.goodsNumber = goodsNumber
                data.marketPrice = data.goodsSp?.[0].marketPrice || 0
                data.shopPrice = data.goodsSp?.[0].shopPrice
                data.promotePrice = data.goodsSp?.[0].promotePrice
                data.weight = data.goodsSp?.[0].weight
            } else if (data.serviceType === "coupon") {
                data.goodsRuleVo = {
                    ruleId: data.ruleId,
                    startDate: data.validity[0].getTime(),
                    endDate: data.validity[1]?.getTime(),
                    guarantee: [].concat(data.isUnconsumed ? 1 : [], data.isOutDate ? 2 : []).join(","),
                    description: data.description,
                }

                data.goodsNumber = data.couponNumber
                data.shopPrice = data.couponPrice

                if (data.usageTimeType === "custom" && data.usageTime?.length === 2) {
                    data.goodsRuleVo.usageTime = `${data.usageTime[0].replace(/^(\d+:\d+):?.*/, "$1")}-${data.usageTime[1].replace(/^(\d+:\d+):?.*/, "$1")}`
                } else {
                    data.goodsRuleVo.businessHours = "1"
                }

                delete data.goodsSp
            } else {
                var period = await this.getPeriod(data)
                if (!period) return false
                data.goodsPeriodSetRos = period
            }

            data.isHot = data.isHot ? 1 : 0
            data.isBest = data.isBest ? 1 : 0
            data.isPromote = data.isPromote ? 1 : 0

            // 获取商品描述
            data.goodsDesc = document.getElementById(this.ueditor_id).contentWindow.getContent()

            data.oemCode = parent.vue.oemInfo.oemCode
            // 初始化默认值
            data.catId = data.brandId = 0

            // 是否开启促销
            if (data.isPromote === 1) {
                // 数组解析为开始和结束时间
                data.promoteStartDate = data.promoteDate[0].getTime() / 1000
                data.promoteEndDate = data.promoteDate[1].getTime() / 1000
            }

            return data
        },

        /**
         * 获取营业时间
         */
        getBusinessHours() {
            return new Promise(resolve => {
                if (this.businessHours && this.businessHours.supplierId === this.supplierId) {
                    resolve(this.businessHours.value)
                }

                this.$get("/gateway/apps/o2o/api/o2o/admin/getBriefSupplierById", {
                    supplierId: this.supplierId,
                }).then(res => {
                    if (res.code == 200) {
                        let data = res.data
                        this.businessHours = {
                            supplierId: this.supplierId,
                            value: {
                                openStart: data.openStart,
                                openEnd: data.openEnd,
                            },
                        }
                        resolve(this.businessHours.value)
                    }
                })
            })
        },

        getTypeName(serviceType) {
            switch (serviceType) {
                case "coupon":
                    return "到店核销券"
                case "visit_service":
                    return "预约上门服务"
                case "service":
                    return "预约到店服务"
                default:
                    return "实物商品"
            }
        },
    },
}
</script>
