<template>
    <div class="residentdatabase-my-business">
        <CompTable title="数采服务系统模版" :columns="columns" table-api="/gateway/api/fastDev/AppTemplate/findPage?busiCategory=&bizCategoryA=JGX"></CompTable>
        <!-- 二维码 -->
        <CompQrCode ref="comp_qrcode"></CompQrCode>
        <!-- 授权 -->
        <Authorize ref="authorize"></Authorize>
    </div>
</template>

<script>
import Route from "../communitymobilization/entity/Route"
import CompTable from "../jointly/components/comp-table.vue"
import CompQrCode from "../residentdatabase/components/CompQrCode.vue"
import Authorize from "./children/Authorize.vue"

import proxy from "@/api/proxy"

export default {
    components: { CompTable, CompQrCode, Authorize },

    data() {
        const orgCode = this.$core.getUrlParam("orgCode") || parent.vue.loginInfo.userinfo.orgCode

        return {
            orgCode: orgCode,

            columns: [
                {
                    title: "功能名称",
                    key: "appName",
                    minWidth: 300,
                    align: "center",
                },
                {
                    title: "描述",
                    key: "description",
                    minWidth: 300,
                    align: "center",
                },
                {
                    title: "模版ID",
                    key: "templateId",
                    align: "center",
                },
                {
                    title: "编码",
                    key: "appCode",
                    align: "center",
                },
                {
                    title: "状态",
                    key: "status",
                    minWidth: 200,
                    align: "center",
                },
                {
                    float: "right",
                    title: "操作",
                    width: 400,
                    align: "center",
                    render: (h, params) => {
                        return h("div", [
                            h(
                                "Button",
                                {
                                    props: {
                                        type: "default",
                                        size: "small",
                                    },
                                    style: "margin:0 3px",
                                    nativeOn: {
                                        click: () => {
                                            this.$refs.comp_qrcode.display(`${proxy["/custom"].target}/#/apps/suiyue/pages/residentdatabase/form/form?ti=${params.row.templateId}&type=DYTX`, params.row.appName, this.$core.getUrlParam("orgName"))
                                        },
                                    },
                                },
                                "生成二维码"
                            ),
                            h(
                                "Button",
                                {
                                    props: {
                                        type: "info",
                                        size: "small",
                                    },
                                    style: "margin:0 3px",
                                    nativeOn: {
                                        click: () => {
                                            this.$refs.authorize.display(params.row.templateId)
                                        },
                                    },
                                },
                                "授权给机构"
                            ),
                            h(
                                "Button",
                                {
                                    props: {
                                        type: "success",
                                        size: "small",
                                    },
                                    style: "margin:0 3px",
                                    nativeOn: {
                                        click: () => {
                                            Route.jump(`/fastcustomizationauthorized?templateId=${params.row.templateId}&title=${params.row.appName}`)
                                        },
                                    },
                                },
                                "已授权的机构"
                            ),
                        ])
                    },
                },
            ],
            appCode: null,
        }
    },
}
</script>
<style lang="less">
.residentdatabase-back-btn,
.residentdatabase-add-btn {
    cursor: pointer;
    height: 35px;
    padding: 0 35px;
    margin: 2px 6px !important;
    background: #2faaf7;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    border: 1px solid #e3e3e3;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

.residentdatabase-back-btn {
    background: #fff;
    color: #333;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.03);
}
</style>
