<template>
    <div class="governancenetwork">
        <div class="top-div">
            <!-- <div class="left">农林街党建引领全域服务治理网络</div> -->
            <div class="right"></div>
        </div>
        <div class="title-div">
            <div class="title">农林街党建引领全域服务治理网络</div>
        </div>
        <div class="content-div">
            <div class="left">
                <div class="title">党建引领政府主导多元共建组织联系工作协调机制</div>
                <div class="content">
                    <div v-for="(item, index) in templateList" class="item" :key="index" @click="jumpSendDetail(item.rootOrgName)">{{ item.rootOrgName }}</div>
                </div>
            </div>
            <div class="right">
                <div class="title">党建引领政府主导参入民生服务社区治理共建实践</div>
                <div class="right-content">
                    <div class="content">
                        <div class="left">
                            <div class="item">社区小广播</div>
                            <div class="item">你言我语</div>
                            <div class="item">社区事好商量</div>
                            <div class="item">小区物业</div>
                            <div class="item">垃圾分类</div>
                            <div class="item">社区居民公约</div>
                            <div class="item">社区自家人直播间</div>
                            <div class="item">健康义诊</div>
                        </div>
                        <div class="right">
                            <div class="item">民生微实事</div>
                            <div class="item">有呼必应</div>
                            <div class="item">社区两委班子</div>
                            <div class="item">社区卫生</div>
                            <div class="item">安全生产通知</div>
                            <div class="item">社区食堂菜谱</div>
                            <div class="item">社区活动</div>
                            <div class="item">志愿服务活动</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            templateList: [],
        }
    },
    async created() {
        await this.getTemplateList()
    },
    methods: {
        getTemplateList() {
            return this.$get("/gateway/org/pc/grassrootsOrg/selectTemplateList").then(res => {
                if (res.code === "200") {
                    this.templateList = res.dataList?.filter(v => v.orgType != 1 && v.orgType != 3 && v.orgType != 4)
                    // this.templateList = res.dataList
                    console.log(this.templateList)
                }
            })
        },
        jumpSendDetail(rootOrgName) {
            this.$core.openLayerFrame({
                type: 2,
                title: rootOrgName,
                content: `/page#/governancenetworkdetail`,
                area: ["100%", "100%"],
            })
        },
    },
}
</script>

<style lang="less" scoped>
.governancenetwork {
    width: 100vw;
    height: 100vh;
    background-image: url("https://liefeng.oss-cn-shenzhen.aliyuncs.com/PROD/zjsm/20250113/127131452.jpg");
    background-size: 100% 100%;
    overflow: scroll;
    .top-div {
        font-size: 28px;
        font-weight: bold;
        margin: 20px;
        .left {
        }
        .right {
        }
    }
    .title-div {
        text-align: center;
        margin: 20px 0;
        font-size: 32px;
        font-weight: bold;
        .title {
            color: red;
        }
    }
    .content-div {
        display: flex;
        justify-content: center;
        height: 100%;
        .left {
            flex: 1;
            margin: 0 40px;
        }
        .right {
            flex: 1;
            margin: 0 40px;
            .right-content {
                background: #fffcfc;
                border: 2px solid #fbe8bf;
                border-radius: 5px;
                // height: 75%;
                .content {
                    // height: 100%;
                    display: flex;
                }
                .left {
                    width: 45%;
                    margin-right: 20px;
                }
                .right {
                    width: 45%;
                }
                .item {
                    cursor: pointer;
                    width: 100%;
                    border-radius: 20px;
                    border: 2px solid #fbe8bf;
                    padding: 10px 0;
                    text-align: center;
                    font-size: 18px;
                    font-weight: bold;
                }
            }
        }
        .title {
            text-align: center;
            color: red;
            font-size: 28px;
        }
        .content {
            background: #fffcfc;
            border: 2px solid #fbe8bf;
            border-radius: 5px;
            // overflow-y: scroll;
            // height: 75%;
            .item {
                cursor: pointer;
                width: 60%;
                margin: 20px auto;
                border-radius: 20px;
                border: 2px solid #fbe8bf;
                padding: 10px 0;
                text-align: center;
                font-size: 18px;
                font-weight: bold;
            }
        }
    }
}
</style>