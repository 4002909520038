<template>
    <div class="page">
        <CompContent :title="(category === 'personnel' ? '人员' : '动员') + '体系架构 - ' + orgName">
            <template #operate>
                <div style="display: flex;align-items: center;padding-right: 10px;line-height: 1;">
                    <span style="margin-right:5px">缩放：</span>
                    <Slider :min="50" v-model="zoom" style="width:200px" :tip-format="v => v + '%'"></Slider>
                </div>
                <Button type="info" v-if="edit" @click="edit = false" icon="md-checkmark" style="margin:5px 5px 5px 10px;">完成修改</Button>
                <Button type="info" v-else @click="edit = true" icon="md-hammer" style="margin:5px 5px 5px 10px;">修改当前架构</Button>
                <Button type="error" icon="md-close" style="margin:5px 5px 5px 10px;" @click="onDeleteFramework">删除当前架构</Button>
            </template>
            <template>
                <!-- <div class="node-title" v-if="false">
                    <div class="item-box" style="width: 320px">党支部</div>
                    <div class="item-box" style="width: 320px">党小组</div>
                    <div class="item-box" style="width: 170px">责任区</div>
                </div> -->
                <div style="min-height: 100%" :style="'transform: scale(' + zoom / 100 + ');'">
                    <div class="flow-tree-box">
                        <FlowTree v-for="(tree, index) in trees" :key="index" :edit="edit" color="rgb(217, 0, 27)" :node="tree" arrange="across" :keys="{ child: 'subs' }" :styles="{ height: 'auto', width: 'auto', background: 'rgb(255, 254, 208)' }" :change-before="onChangeNodeBefore">
                            <template #default="evt">
                                <div class="first-item" v-if="evt.item.first">
                                    <p class="title">{{ evt.item.rootOrgName }}</p>
                                    <Icon class="icon" v-if="edit" type="md-create" @click="onDisplayChangeName(evt.item)" />
                                </div>
                                <div class="tree-item" v-else-if="evt.item.postList && evt.item.postList.length > 0" @click="onDisplayPrincipal(evt, evt.item.members || evt.item.postList, evt.level - 1)" style="width:350px">
                                    <div class="title-box">
                                        <p class="value">{{ evt.item.rootOrgName }}</p>
                                        <Icon v-if="edit" type="md-create" />
                                    </div>
                                    <div class="operating-box">
                                        <div class="item-box" v-for="(item, idx) in evt.item.members || evt.item.postList" :key="idx">
                                            <p class="title">{{ item.postName }}:</p>
                                            <div class="value" v-if="item.post">
                                                <b>{{ item.post.userName }}</b>
                                                <b v-if="item.post.mobile">{{ item.post.mobile }}</b>
                                                <b :style="{ color: item.post.activeStatus == '1' ? 'green' : 'red' }">{{ item.post.activeStatus == "1" ? "已激活" : "未激活" }}</b>
                                            </div>
                                            <p class="value pointer" v-else>暂无负责人，请前往设置</p>
                                        </div>
                                    </div>
                                    <div class="group-box" v-show="evt.item.isDisplayMore">
                                        <div class="item-box">
                                            <p class="title">畅联组：</p>
                                            <p class="value" :style="{ background: evt.item.contactGroup === '1' ? '#23b023' : '#f15151' }">{{ evt.item.contactGroup === "1" ? "已建" : "未建" }}</p>
                                        </div>
                                        <div class="item-box">
                                            <p class="title">微信群：</p>
                                            <p class="value" :style="{ background: evt.item.weixinGroup === '1' ? '#23b023' : '#f15151' }">{{ evt.item.weixinGroup === "1" ? "已建" : "未建" }}</p>
                                        </div>
                                    </div>
                                    <div class="remark-box" v-if="evt.item.remark" v-show="evt.item.isDisplayMore">
                                        <p class="title">包括门牌：</p>
                                        <p class="text">{{ evt.item.remark }}</p>
                                    </div>
                                    <div class="unfold-more-btn" @click.stop="onChangeDisplayMore(evt)">
                                        <Icon class="icon" :class="{ rotate: evt.item.isDisplayMore }" type="ios-arrow-down" size="16" color="#c0c0c0" />
                                    </div>
                                </div>
                                <div class="box" style="height:90px;cursor:pointer;width:200px" v-else @click="onDisplayDisconnect(evt)">
                                    <div v-if="category === 'personnel' && evt.item.representativeCount > 0" style="text-align:center;">
                                        <p class="title" style="color:#e45f5f;font-size:16px;font-weight: bold;">{{ evt.item.rootOrgName }}</p>
                                        <p class="value" style="color:#5ab2e9;font-size:14px">（已选 {{ evt.item.representativeCount || 0 }} 人）</p>
                                    </div>
                                    <div v-else-if="category !== 'personnel' && evt.item.houseCount > 0" style="text-align:center;">
                                        <p class="value" style="color:#5ab2e9">已划分{{ evt.item.houseCount }}户</p>
                                        <p class="value" style="color:#5ab2e9;font-size:13px">（已有{{ evt.item.representativeCount || 0 }}户代表）</p>
                                    </div>
                                    <p class="value" v-else style="color:red">前去划分</p>
                                </div>
                            </template>
                        </FlowTree>
                    </div>
                </div>
            </template>
        </CompContent>

        <!-- 责任区划分 -->
        <CompModal ref="distribution_modal" title="划分房号" width="80%">
            <template #head-operate>
                <div class="modal-operate-btn" @click="onSubmitDistribution">保存</div>
            </template>
            <div style="padding:0 20px">
                <Distribution ref="distribution" :edit="true" @on-change="getTreeData"></Distribution>
            </div>
        </CompModal>

        <Principal ref="principal" @on-change="getTreeData()"></Principal>

        <CompModal ref="edit_form_modal" title="修改体系架构" height="auto">
            <div style="padding: 10px 30px 10px 20px">
                <ElemFormItem title="体系架构名称" name="name">
                    <Input style="width:350px" v-model="edit_formdata.name" :maxlength="50" placeholder="请填写体系架构名称"></Input>
                </ElemFormItem>
            </div>
            <template #operate="evt">
                <div class="operate-btns">
                    <div class="operate-btn highlight" @click="onSubmitEditRootOrg">保存</div>
                    <div class="operate-btn" @click="evt.close">取消</div>
                </div>
            </template>
        </CompModal>

        <!-- 人员管理相关组件 -->
        <PartitionMember v-if="category === 'personnel'"></PartitionMember>
        <Member v-if="category === 'personnel'" ref="member" @on-close="getTreeData()"></Member>
        <MemberPrincipal v-if="category === 'personnel'" ref="member_principal" @on-change="getTreeData()"></MemberPrincipal>
    </div>
</template>

<script>
import FlowTree from "./components/FlowTree.vue"
import CompContent from "../customform/components/CompContent"
import Principal from "./children/Principal"
import Distribution from "./components/Distribution"

import ElemFormItem from "@/views/jointly/components/elem-form-item.vue"
import CompModal from "../residentdatabase/components/CompModal.vue"
import UserInfo from "./entity/UserInfo"
import Route from "./entity/Route"

// 人员组件
import PartitionMember from "./children/PartitionMember.vue"
import Member from "./children/Member.vue"
import MemberPrincipal from "./children/MemberPrincipal"

import Request from "../jointly/utils/request"

export default {
    components: {
        FlowTree,
        CompContent,
        Principal,
        Distribution,
        CompModal,
        ElemFormItem,
        PartitionMember,
        Member,
        MemberPrincipal,
    },

    data() {
        return {
            route: Route,
            edit: false,
            trees: null,
            disconnect_id: null,
            orgName: null,
            edit_formdata: {},
            // 类型
            category: this.$core.getUrlParam("c"),
            // 缩放
            zoom: 100,
        }
    },

    created() {
        this.orgName = UserInfo.get("orgName")
    },

    async mounted() {
        await this.getTreeData()

        console.log('trees2',this.trees);
    },

    methods: {
        async getTreeData() {
            let id = this.$core.getUrlParam("i") || ""

            if (!id) return

            let load = this.$Message.loading("加载中...")

            await this.$get(`/gateway/org/pc/grassrootsOrg/${this.category === "personnel" ? "v2/" : ""}generaRootOrgById`, {
                rootOrgId: id,
            })
                .then(res => {
                    if (res.code === "200") {
                        let trees = []

                        for (let i = 0, l = res.dataList; i < l.length; i++) {
                            let v = l[i]

                            if (v.rootOrgId === id) {
                                v.width = "60px"
                                v.style = {
                                    "min-height": "500px",
                                    background: "red",
                                    display: "flex",
                                    "align-items": "center",
                                    "border-color": "red",
                                }
                                v.root_id = v.rootOrgId
                                v.edit = "increase"
                                v.unfold = false
                                v.first = true
                                // 处理树节点
                                this.processTreeNode(v.subs, v.rootOrgId, v.config)
                                // 添加符合条件的树
                                trees.push(v)
                                break
                            }
                        }

                        this.trees = trees

                        console.log('trees',this.trees);
                        
                    } else this.$Message.error(res.desc || "获取数据失败")
                })
                .finally(load)
        },

        /**
         * 处理树节点
         */
        processTreeNode(nodes, root_id, config) {
            for (let i = 0; i < nodes?.length; i++) {
                let v = nodes[i]
                v.root_id = root_id
                v.config = config

                if (v.members) {
                    for (let mi = 0, ml = v.members; mi < ml.length; mi++) {
                        const pv = ml[mi]
                        for (let si = 0, sl = pv.admins; si < sl?.length; si++) {
                            const sv = sl[si]
                            if (sv.workStatus == 1) {
                                pv.post = sv
                                break
                            }
                        }
                    }
                }

                if (v.subs?.length > 0) {
                    // 当前节点为责任区前一级节点，不允许增加责任区节点
                    if (!v.subs[0]?.postList?.length > 0) {
                        // 编辑模式仅为移除模式
                        v.edit = "decrease"
                    }
                    // 递归处理子节点
                    this.processTreeNode(v.subs, root_id, config)
                }
            }
        },

        async onDisplayDisconnect(evt) {
            console.log(evt)
            this.disconnect_id = evt.item.rootOrgId

            let parentNames = []

            for (let i = 0, l = evt.parents; i < l.length; i++) {
                parentNames.push(l[i].rootOrgName)
            }

            // 人员架构 - 人员管理
            if (this.category === "personnel") {
                const cfn = evt.item.config
                const appId = await this.getAppId(cfn.subList[cfn.subList.length - 1].appId)

                return this.$refs.member.display(this.disconnect_id, parentNames, appId)
            }

            this.$refs.distribution.setNodeInfo(this.disconnect_id, evt.item.houseCount > 0 ? "edit" : "select", parentNames)
            this.$refs.distribution_modal.display()
        },

        async getAppId(id) {
            if (!this.appIds) this.appIds = {}

            if (!this.appIds[id]) {
                this.appIds[id] = await this.getAppIdByTemplateId(id, parent.vue.loginInfo.userinfo.orgCode)
            }

            return this.appIds[id]
        },

        getAppIdByTemplateId(id, orgCode) {
            return new Promise(async resolve => {
                Request.get("/gateway/api/fastDev/AppForm/findAppByTemplateIdAndOrgCode", {
                    templateId: id,
                    orgCode: orgCode,
                }).then(res => {
                    if (!res) return this.$Message.error((this.error = "当前机构尚未授权，请联系管理员！"))
                    resolve(res.appId)
                })
            })
        },

        /**
         * 改变节点的前置事件
         */
        onChangeNodeBefore(evt) {
            return new Promise(resolve => {
                // 处理节点增加事件
                if (evt.type === "INCREASE") {
                    let v = evt.parent

                    this.$Message.loading("加载中...")

                    if (this.category === "personnel") {
                        return this.$post(
                            "/gateway/org/pc/grassrootsOrg/v2/generateNextRootOrg",
                            {
                                rootId: v.root_id,
                                parentId: v.rootOrgId,
                                communityCode: parent.vue.loginInfo.userinfo.communityCode,
                                orgType: v.orgType,
                                rootType: v.rootType,
                                depth: evt.level - 1,
                            },
                            { "Content-Type": "application/json" }
                        )
                            .then(res => {
                                if (res.code == 200) {
                                    let data = res.dataList
                                    // 处理节点
                                    this.processTreeNode(data, v.root_id, v.config)
                                    // 返回
                                    resolve(data)
                                } else setTimeout(() => this.$Message.error(res.desc))
                            })
                            .finally(this.$Message.destroy)
                    }

                    return this.$post("/gateway/org/pc/grassrootsOrg/generateNextRootOrg", {
                        rootId: v.root_id,
                        parentId: v.rootOrgId,
                        parentLevel: evt.level,
                    })
                        .then(res => {
                            if (res.code == 200) {
                                let data = res.dataList
                                // 处理节点
                                this.processTreeNode(data, v.root_id, v.config)
                                // 返回
                                resolve(data)
                            } else setTimeout(() => this.$Message.error(res.desc))
                        })
                        .finally(this.$Message.destroy)
                }

                // 处理节点移除事件
                let v = evt.value

                let d = () => {
                    this.$Message.loading("加载中...")

                    this.$post(
                        "/gateway/org/pc/grassrootsOrg/deleteOrgNodeForce",
                        {
                            rootId: v.root_id,
                            rootOrgId: v.rootOrgId,
                        },
                        { "Content-Type": "application/json" }
                    )
                        .then(res => {
                            if (res.code == 200) resolve(true)
                            else {
                                setTimeout(() => this.$Message.error(res.desc))
                                // 停止
                                resolve(false)
                            }
                        })
                        .finally(this.$Message.destroy)
                }

                if (v.subs?.length > 0) {
                    this.$Modal.confirm({
                        title: "提示",
                        content: "移除当前节点将一并移除该子节点，是否确认此操作？",
                        onOk: d,
                    })
                } else d()
            })
        },

        /**
         * 提交房号划分事件
         */
        async onSubmitDistribution() {
            let { selected, unselect } = this.$refs.distribution.getSelected()
            if (selected?.length > 0) await this.onChangeOrgArea("add", selected)
            if (unselect?.length > 0) await this.onChangeOrgArea("remove", unselect)
            // 提示
            this.$Message.success("保存成功")
            // 关闭弹窗
            this.$refs.distribution_modal.close()
            // 加载数据
            this.getTreeData()
        },

        /**
         * 修改责任区
         */
        onChangeOrgArea(type, selected) {
            return new Promise(resolve => {
                this.$post(
                    `/gateway/org/pc/grassrootsOrg/${type === "add" ? "addOrgArea" : "deleteOrgArea"}`,
                    {
                        communityCode: UserInfo.getCommunityCode(),
                        rootOrgId: this.disconnect_id,
                        houseCodes: selected,
                    },
                    { "Content-Type": "application/json" }
                ).then(res => {
                    if (res.code == 200) {
                        resolve()
                    } else {
                        this.$Message.error(res.desc)
                    }
                })
            })
        },

        /**
         * 显示设置负责人页面
         */
        async onDisplayPrincipal(evt, posts, level) {
            if (this.category === "personnel") {
                const cfn = evt.item.config
                const appId = await this.getAppId(cfn.subList[level].appId)

                return this.$refs.member_principal.display(appId, evt.item, posts, level)
            }

            this.$refs.principal.display(evt.item, posts, level)
        },

        onChangeDisplayMore(evt) {
            this.$set(evt.item, "isDisplayMore", !evt.item.isDisplayMore)
        },

        /**
         * 显示架构名称更改弹窗
         */
        onDisplayChangeName(item) {
            this.edit_formdata = {
                data: item,
                name: item.rootOrgName,
            }
            this.$refs.edit_form_modal.display()
        },

        /**
         * 提交架构名称修改
         */
        onSubmitEditRootOrg() {
            let fd = this.edit_formdata

            this.$post(
                `/gateway/org/pc/grassrootsOrg/${this.category === "personnel" ? "v2/" : ""}updateOrgInfo`,
                {
                    ...fd.data,
                    rootOrgLevel: 1,
                    rootOrgName: fd.name,
                    orgCodeSelf: parent.vue.loginInfo.userinfo.orgCode,
                },
                { "Content-Type": "application/json" }
            ).then(res => {
                if (res.code == 200) {
                    this.$Message.success("保存成功")
                    // 更新名称
                    this.$set(fd.data, "rootOrgName", fd.name)
                    // 关闭弹窗
                    this.$refs.edit_form_modal.close()
                } else this.$Message.error(res.desc)
            })
        },

        /**
         * 删除架构
         */
        onDeleteFramework() {
            this.$Modal.confirm({
                title: "提示",
                content: "当前删除操作将不可恢复，请谨慎操作，是否继续？",
                onOk: () => {
                    this.$post(
                        "/gateway/org/pc/grassrootsOrg/deleteOrgNodeForce",
                        {
                            rootId: this.$core.getUrlParam("i"),
                            rootOrgId: this.$core.getUrlParam("i"),
                        },
                        {
                            "Content-Type": "application/json",
                        }
                    ).then(res => {
                        if (res.code == 200) {
                            this.$Message.success({
                                content: "删除成功",
                                background: true,
                            })
                            setTimeout(() => {
                                // 返回上一页
                                Route.back()
                            }, 1000)
                        } else {
                            this.$Message.error({
                                content: res.desc || "删除失败",
                                background: true,
                            })
                        }
                    })
                },
            })
        },
    },
}
</script>

<style lang="less" scoped>
.page {
    /deep/#contentarea {
        top: 60px;
        padding-top: 0;
    }

    .node-title {
        position: sticky;
        top: 0;
        display: flex;
        align-items: center;
        padding-left: 70px;
        height: 50px;
        background: #fff;
        border-bottom: 1px solid #f3f3f3;
        z-index: 300;

        > .item-box {
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 18px;
            border-right: 1px solid #f3f3f3;
            font-weight: bold;

            &:last-child {
                border-right: 0;
            }
        }
    }

    .flow-tree-box {
        min-height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        box-sizing: border-box;

        .unfold-more-btn {
            cursor: pointer;
            width: 100%;
            height: 24px;
            display: flex;
            align-items: center;
            justify-content: center;

            .icon {
                transition: all 0.1s;
            }

            .rotate {
                transform: rotate(180deg);
            }
        }

        .first-item {
            padding: 20px;
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            box-sizing: border-box;

            .title {
                color: #fff;
                font-size: 25px;
                text-align: center;
                line-height: 28px;
            }

            .icon {
                cursor: pointer;
                margin-top: 10px;
                color: #fff;
                font-size: 22px;
            }
        }
    }

    .tree-item {
        cursor: pointer;
        padding: 15px 20px 0 20px;

        .title-box {
            cursor: pointer;
            display: flex;
            align-items: center;

            .value {
                font-size: 18px;
                line-height: 30px;
                color: #e45f5f;
                margin-right: 10px;
                font-weight: bold;
            }
        }

        .operating-box {
            padding-top: 10px;

            > .item-box {
                display: flex;
                align-items: center;
                margin-top: 5px;

                &:first-child {
                    margin-top: 0;
                }

                .title {
                    padding-right: 10px;
                    color: #666;
                    flex-shrink: 0;
                    font-size: 13px;
                }

                .value {
                    color: #000;

                    &.pointer {
                        cursor: pointer;
                        color: #43aaea;
                    }

                    b {
                        margin-right: 10px;
                        font-weight: 400;
                    }
                }
            }
        }

        .group-box {
            padding-top: 10px;
            margin-top: 10px;
            border-top: 1px solid rgba(0, 0, 0, 0.05);

            > .item-box {
                display: flex;
                align-items: center;
                margin-top: 5px;

                &:first-child {
                    margin-top: 0;
                }

                .title {
                    padding-right: 10px;
                    color: #666;
                    font-size: 13px;
                }

                .value {
                    line-height: 1;
                    color: #fff;
                    padding: 5px 12px;
                    font-size: 12px;
                    font-weight: bold;
                    border-radius: 4px;
                }

                .text {
                    cursor: pointer;
                    color: #43aaea;
                }
            }
        }

        .remark-box {
            padding-top: 10px;
            margin-top: 10px;
            border-top: 1px solid rgba(0, 0, 0, 0.05);
            display: flex;
            align-items: center;

            .title {
                padding-right: 10px;
                color: #666;
                font-size: 13px;
                flex-shrink: 0;
            }

            .text {
                color: #333;
                font-size: 13px;
            }
        }
    }
}

.modal-operate-btn {
    cursor: pointer;
    padding: 4px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #2faaf7;
    color: #fff;
    border-radius: 4px;
}
</style>
