import { render, staticRenderFns } from "./orderaddress.vue?vue&type=template&id=816e18fa&scoped=true&"
import script from "./orderaddress.vue?vue&type=script&lang=js&"
export * from "./orderaddress.vue?vue&type=script&lang=js&"
import style0 from "./orderaddress.vue?vue&type=style&index=0&id=816e18fa&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "816e18fa",
  null
  
)

export default component.exports