<template>
    <div class="governancenetwork">
        <div class="top-div">
            <div class="title">{{$route.query.rootOrgName}}</div>
        </div>
        <div class="content-div">
            <div class="content-item-div">
                <div v-for="(item, index) in orgList" class="item" :key="index" @click="changeJump(item)">
                    <div class="name">{{ item.rootOrgName }}</div>
                    <div class="fuzeren" v-for="(items, indexs) in item.members" :key="indexs">
                        {{ items.postName }}:
                        <span class="fuzerename" v-for="(itemss, indesxx) in items.admins" :key="indesxx">
                            <template v-if="itemss.workStatus == 1"> {{ itemss.userName }}</template>
                        </span>
                    </div>
                    <div class="num">
                        <div class="items">
                            <div class="title">党小组数</div>
                            <div class="nums">{{item.subs.length || 0}}</div>
                        </div>
                        <div class="items">
                            <div class="title">联系居民数</div>
                            <div class="nums">{{item.representativeCount || 0}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

         <!-- 责任区划分 -->
        <CompModal ref="distribution_modal" :title="titleName" width="80%">
            <div style="padding:0 20px">
                <Distribution :communityCode="$route.query.communityCode" ref="distribution" :edit="true" @on-change="getTreeData"></Distribution>
            </div>
        </CompModal>
    </div>
</template>

<script>
import LiefengModal from "@/components/LiefengModal"
import CompModal from "../residentdatabase/components/CompModal.vue"
import Distribution from "../communitymobilization/components/Distribution"
export default {
    components: { LiefengModal,CompModal,Distribution },
    data() {
        return {
            orgList: [],
            selectIndex: 0,
            titleName:''
        }
    },
    async created() {
        this.orgList = JSON.parse(window.sessionStorage.getItem('subsList')) 
        console.log(this.orgList);
        
    },
    methods: {
        async changeJump(items){
            this.titleName = items.rootOrgName
            
            var subList2 = JSON.parse(window.sessionStorage.getItem('subList2'))
            var list = {...subList2,item:items.subs[0],parents:[...subList2.parents,items],node:[items.subs[0]],nodes:[items.subs[0]] }
            this.onDisplayDisconnect(list)
            // this.disconnect_id = item.subs[0].rootOrgId || item.rootOrgId

            // let parentNames = []

            // this.$refs.distribution.setNodeInfo(this.disconnect_id, "select", parentNames)
            // this.$refs.distribution_modal.display()
        },

        async onDisplayDisconnect(evt) {
            console.log(evt)
            this.disconnect_id = evt.item.rootOrgId

            let parentNames = []

            for (let i = 0, l = evt.parents; i < l.length; i++) {
                parentNames.push(l[i].rootOrgName)
            }

            console.log('parentNames',parentNames);
            
            this.$refs.distribution.setNodeInfo(this.disconnect_id, evt.item.houseCount > 0 ? "edit" : "select", parentNames)
            this.$refs.distribution_modal.display()
        },
        async getAppId(id) {
            if (!this.appIds) this.appIds = {}

            if (!this.appIds[id]) {
                this.appIds[id] = await this.getAppIdByTemplateId(id, parent.vue.loginInfo.userinfo.orgCode)
            }

            return this.appIds[id]
        },
    },
}
</script>

<style lang="less" scoped>
.governancenetwork {
    width: 100vw;
    height: 100vh;
    background-image: url("https://liefeng.oss-cn-shenzhen.aliyuncs.com/PROD/zjsm/20250113/127131452.jpg");
    overflow: hidden;
    .top-div {
        width: 50%;
        text-align: center;
        border: 2px solid #fbe8bf;
        border-radius: 5px;
        margin: 20px auto;
        background-color: #fff;
        padding: 20px;
        .title {
            font-size: 18px;
            color: red;
        }
        .title2 {
            font-size: 24px;
            color: red;
            font-weight: bold;
        }
        .title3 {
            font-size: 16px;
            color: #333;
            font-weight: bold;
        }
    }
    .content-div {
        margin: 20px;
    }
    .type-list {
        display: flex;
        .item {
            width: 20%;
            text-align: center;
            margin: 0 10px;
            background: #fff;
            border: 2px solid #fbe8bf;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
            padding: 10px;
            &:nth-child(1),
            &:nth-last-child(1) {
                margin: 0;
            }
            .orgname {
                color: red;
                font-size: 18px;
                font-weight: bold;
                height: 50px;
            }
            .name {
                color: #333;
            }
        }
        .active {
            background: #fbe8bf;
        }
    }
    .content-item-div{
        background: #fbe8bf;
        display: flex;
        flex-wrap: wrap;
        .item{
            width: 20%;
            margin: 20px ;
            background: #fff;
            padding: 20px;
            border-radius: 10px;
            cursor: pointer;
            .name{
                font-weight: bold;
                color: #333;
                text-align: center;
            }
            .fuzeren{
                display: flex;
                justify-content: center;
                .fuzerename{
                    margin-left: 20px;
                    color: #29cb97;
                }
            }
            .num{
                display: flex;
                justify-content: center;
                text-align: center;
                .items{
                    margin: 20px;
                    .title{}
                    .nums{
                        margin-top: 5px;
                    }
                }
            }
        }
    }
}
</style>