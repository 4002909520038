<template>
    <LiefengContent>
        <template v-slot:title>收支明细</template>
        <template v-slot:contentArea>
            <LiefengTable
                :talbeColumns="tableColumns"
                :tableData="tableData"
                :loading="loading"
                :fixTable="true"
                :curPage="page"
                :total="total"
                :pagesizeOpts="[20, 30, 50, 100]"
                :page-size="pageSize"
                @hadlePageSize="hadlePageSize"
            ></LiefengTable>
        </template>
    </LiefengContent>
</template>

<script>
import LiefengContent from "@/components/LiefengContent3"
import LiefengTable from "@/components/LiefengTable"
export default {
    components: { LiefengContent, LiefengTable },
    data() {
        return {
            tableData: [], //table的数据
            loading: false, //table 加载
            page: 1, //分页
            total: 0, //总数量
            pageSize: 20, //每一页显示的数量
            tableColumns: [
                {
                    title: "交易编号",
                    align: "center",
                    minWidth: 100,
                    key: "orderNo",
                },
                {
                    title: "交易标题",
                    align: "center",
                    minWidth: 100,
                    key: "chargeTitle",
                },
                {
                    title: "交易类型",
                    align: "center",
                    minWidth: 100,
                    key: "chargeType",
                    render: (h, params) => {
                        return h("div", {}, params.row.chargeType == "1" ? "收入" : params.row.chargeType == "2" ? "支出" : "未知")
                    },
                },
                {
                    title: "交易渠道",
                    align: "center",
                    minWidth: 100,
                    key: "orderSource",
                    render: (h, params) => {
                        return h("div", {}, params.row.orderSource == "1" ? "收银台" : params.row.orderSource == "2" ? "商城" : "未知")
                    },
                },
                {
                    title: "交易金额(元)",
                    align: "center",
                    minWidth: 100,
                    key: "orderAmount",
                    render: (h, params) => {
                        return h("div", {}, (params.row.chargeType == "2" ? "-" : "") + params.row.amount && params.row.amount != "" ? Number(params.row.amount) / 100 : 0)
                    },
                },

                 {
                    title: "支付方式",
                    align: "center",
                    minWidth: 100,
                    key: "channelPay",
                    render: (h, params) => {
                        return h("div", {}, params.row.channelPay == "ali" ? "支付宝" : params.row.orderSource == "weixin" ? "微信" : "未知")
                    },
                },

                {
                    title: "用户昵称",
                    align: "center",
                    minWidth: 100,
                    key: "nickname",
                },
                {
                    title: "用户头像",
                    align: "center",
                    minWidth: 100,
                    key: "avatar",
                    render: (h, params) => {
                        return params.row.avatar
                            ? h("img", {
                                  style: {
                                      width: "30px",
                                      height: "30px",
                                  },
                                  attrs: {
                                      src: params.row.avatar,
                                  },
                              })
                            : h("p", null, "-")
                    },
                },
                {
                    title: "交易时间",
                    align: "center",
                    minWidth: 100,
                    key: "chargeTime",
                    render: (h, params) => {
                        return h("div", {}, params.row.chargeTime ? this.$core.formatDate(new Date(params.row.chargeTime), "yyyy-MM-dd hh:mm:ss") : "")
                    },
                },
            ],
        }
    },
    created() {
        this.getList()
    },
    methods: {
        reset() {
            this.payStatus = ""
            this.orderStatus = ""
            this.getList()
        },
        //分页
        hadlePageSize(val) {
            this.page = val.page
            this.pageSize = val.pageSize
            this.getList()
        },
        // 接口部分
        // 获取列表接口数据
        getList() {
            this.loading = true
            this.$get("/gateway/api/sycompany/pc/CompanyOrderChargeApi/selectByPage", {
                page: this.page,
                pageSize: this.pageSize,
                companyId: this.$route.query.companyId || "",
            }).then(res => {
                this.loading = false
                if (res.code == 200) {
                    this.tableData = res.dataList.map(item => {
                        return {
                            ...item,
                            ...JSON.parse(item.orderInfo),
                            ...JSON.parse(item.userInfo),
                        }
                    })
                    this.page = res.currentPage
                    this.pageSize = res.pageSize
                    this.total = res.maxCount
                } else {
                    this.$Message.error({
                        content: "获取数据失败",
                        background: true,
                    })
                    return
                }
            })
        },
    },
}
</script>

<style lang="less" scoped>
.read-div {
    display: flex;
    .item {
        margin-right: 20px;
    }
}
</style>