<template>
    <div class="governancenetwork">
        <div class="top-div">
            <div class="title">党建引领政府主导多元共建组织联系工作协调机制</div>
            <div class="title2">{{ $route.query.rootOrgName }}</div>
            <div class="title3">{{ $route.query.adminType && $route.query.adminType != '' && $route.query.adminType != null ? $route.query.adminType : "未知" }}:{{ $route.query.adminUser && $route.query.adminUser != '' && $route.query.adminUser != null ? $route.query.adminUser : "未知" }}</div>
        </div>
        <div class="content-div"  v-if="orgList && orgList.length">
            <div class="type-list">
                <div :class="selectIndex == index ? 'item active' : 'item'" v-for="(item, index) in orgList" :key="index" @click="changeType(index)">
                    <div class="orgname">{{ item.orgName }}</div>
                    <div class="name"></div>
                </div>
            </div>
            <div class="content-item-div" v-if="orgList && orgList.length && orgList[selectIndex].list && orgList[selectIndex].list.length">
                <div v-for="(item, index) in orgList[selectIndex].list" class="item" :key="index" @click="changeJump(item.subs,item.rootOrgName,item.communityCode,item)">
                    <div class="name">{{ item.rootOrgName }}</div>
                    <div class="fuzeren" v-for="(items, indexs) in item.members" :key="indexs">
                        {{ items.postName }}:
                        <span class="fuzerename" v-for="(itemss, indesxx) in items.admins" :key="indesxx">
                            <template v-if="itemss.workStatus == 1"> {{ itemss.userName }}</template>
                        </span>
                    </div>
                    <div class="num">
                        <div class="items">
                            <div class="title">党小组数</div>
                            <div class="nums">{{item.subs.length || 0}}</div>
                        </div>
                        <div class="items">
                            <div class="title">联系居民数</div>
                            <div class="nums">{{item.representativeCount || 0}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="none-div" v-else>
            社区正在完善体系搭建，敬请期待
        </div>
    </div> 
</template>

<script>
import LiefengModal from "@/components/LiefengModal"
export default {
    components: { LiefengModal },
    data() {
        return {
            orgList: [],
            selectIndex: 0,
            trees:[],
            parents:[],
            level:0
        }
    },
    async created() {
        await this.getByOrgCode()
    },
    methods: {
        async changeJump(val,rootOrgName,communityCode,item){    
            await this.getTreeData(item.parentId)
            window.sessionStorage.setItem('subList2',JSON.stringify(this.getChildAttrs(item)))
            window.sessionStorage.setItem('subsList',JSON.stringify(val) )
            this.$core.openLayerFrame({
                type: 2,
                title: rootOrgName,
                content: `/page#/governancenetworkotherdetail?rootOrgName=${rootOrgName}&communityCode=${communityCode}`,
                area: ["100%", "100%"],
            })
        },
        changeType(index) {
            this.selectIndex = index
        },
        async getByOrgCode() {
            return await this.$get("/gateway/org/pc/grassrootsOrg/generaRootOrgStreet", {
                streetCode: "440104012",
                orgType: this.$route.query.orgType,
            }).then(res => {
                if (res && res.dataList && res.dataList.length) {
                    this.orgList = res.dataList.map(item => {
                        this.parents.push(item[0])

                        console.log('parents',this.parents);
                        
                        return {
                            orgName: item[0].communityName,
                            list: item[0].subs,
                        }
                    })
                        
                }else{
                     this.orgList = []
                }
                console.log("this.orgList", this.orgList)
                
            })
        },
        async getTreeData(id) {
            if (!id) return

            let load = this.$Message.loading("加载中...")

            await this.$get(`/gateway/org/pc/grassrootsOrg/generaRootOrgById`, {
                rootOrgId: id,
            })
                .then(res => {
                    if (res.code === "200") {
                        let trees = []

                        for (let i = 0, l = res.dataList; i < l.length; i++) {
                            let v = l[i]

                            if (v.rootOrgId === id) {
                                v.width = "60px"
                                v.style = {
                                    "min-height": "500px",
                                    background: "red",
                                    display: "flex",
                                    "align-items": "center",
                                    "border-color": "red",
                                }
                                v.root_id = v.rootOrgId
                                v.edit = "increase"
                                v.unfold = false
                                v.first = true
                                // 处理树节点
                                this.processTreeNode(v.subs, v.rootOrgId, v.config)
                                // 添加符合条件的树
                                trees.push(v)
                                break
                            }
                        }

                        this.trees = trees

                        console.log('trees', JSON.parse(JSON.stringify(this.trees)));
                        
                    } else this.$Message.error(res.desc || "获取数据失败")
                })
                .finally(load)
        },
        processTreeNode(nodes, root_id, config) {
            for (let i = 0; i < nodes?.length; i++) {
                let v = nodes[i]
                v.root_id = root_id
                v.config = config

                if (v.members) {
                    for (let mi = 0, ml = v.members; mi < ml.length; mi++) {
                        const pv = ml[mi]
                        for (let si = 0, sl = pv.admins; si < sl?.length; si++) {
                            const sv = sl[si]
                            if (sv.workStatus == 1) {
                                pv.post = sv
                                break
                            }
                        }
                    }
                }

                if (v.subs?.length > 0) {
                    // 当前节点为责任区前一级节点，不允许增加责任区节点
                    if (!v.subs[0]?.postList?.length > 0) {
                        // 编辑模式仅为移除模式
                        v.edit = "decrease"
                    }
                    // 递归处理子节点
                    this.processTreeNode(v.subs, root_id, config)
                }
            }
        },
        getChildAttrs(item) {
            return {
                item, // 当前节点所有属性
                node: item[this.getKey("child")],
                nodes: item[this.getKey("child")],
                parents: this.getParents(item),
                level: 3,
            }
        },
        getParents(item) {
            let res = []
            // 拷贝节点
            console.log('parents',this.parents);
            
            this.parents.forEach(v => {
                res.push(v)
            })
            // 添加当前节点
            res.push(item)
            return res
        },
        getKey(key) {
            this.keys = {child: 'subs'}
            return this.keys[key] || key
        },
    },
}
</script>

<style lang="less" scoped>
.governancenetwork {
    width: 100vw;
    height: 100vh;
    background-image: url("https://liefeng.oss-cn-shenzhen.aliyuncs.com/PROD/zjsm/20250113/127131452.jpg");
    overflow: hidden;
    .top-div {
        width: 50%;
        text-align: center;
        border: 2px solid #fbe8bf;
        border-radius: 5px;
        margin: 20px auto;
        background-color: #fff;
        padding: 20px;
        .title {
            font-size: 18px;
            color: red;
        }
        .title2 {
            font-size: 24px;
            color: red;
            font-weight: bold;
        }
        .title3 {
            font-size: 16px;
            color: #333;
            font-weight: bold;
        }
    }
    .content-div {
        margin: 20px;
    }
    .type-list {
        display: flex;
        .item {
            width: 20%;
            text-align: center;
            margin: 0 10px;
            background: #fff;
            border: 2px solid #fbe8bf;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
            padding: 10px;
            &:nth-child(1),
            &:nth-last-child(1) {
                margin: 0;
            }
            .orgname {
                color: red;
                font-size: 18px;
                font-weight: bold;
                height: 50px;
            }
            .name {
                color: #333;
                text-align: center;
            }
        }
        .active {
            background: #fbe8bf;
        }
    }
    .content-item-div{
        background: #fbe8bf;
        display: flex;
        flex-wrap: wrap;
        .item{
            width: 20%;
            margin: 20px ;
            background: #fff;
            padding: 20px;
            border-radius: 10px;
            cursor: pointer;
            .name{
                font-weight: bold;
                color: #333;
                text-align: center;
            }
            .fuzeren{
                display: flex;
                justify-content: center;
                .fuzerename{
                    margin-left: 20px;
                    color: #29cb97;
                }
            }
            .num{
                display: flex;
                justify-content: center;
                text-align: center;
                .items{
                    margin: 20px;
                    .title{}
                    .nums{
                        margin-top: 5px;
                    }
                }
            }
        }
    }
    .none-div{
        text-align: center;
        margin: 20px;
        color: #333;
        font-weight: bold;
        font-size: 20px;
    }
}
</style>