<template>
    <LiefengContent>
        <template v-slot:title>{{ `管理员列表` }}</template>
        <template v-slot:toolsbarRight>
            <Form :label-colon="true" :inline="true" class="search">
                <Button type="info" :loading="btnLoading" @click="syncDeviceAuth">刷新</Button>
                <Button type="primary" @click="previewStatusFn(true)" icon="ios-add" style="margin-left: 10px">新增</Button>
            </Form>
        </template>
        <template v-slot:contentArea>
            <LiefengTable
                :talbeColumns="talbeColumns"
                :tableData="tableData"
                :loading="loading"
                :fixTable="true"
                :curPage="page"
                :total="total"
                :page-size="pageSize"
                :pagesizeOpts="[20, 30, 50, 100]"
                @hadlePageSize="hadlePageSize"
                @tableSelect="tableSelect"
                :hidePage="true"
            ></LiefengTable>

            <LiefengModal :title="'新增管理员'" :fullscreen="false" :value="previewStatus" @input="previewStatusFn">
                <template v-slot:contentarea>
                    <Form :model="sendmsgForm" :rules="sendmsgValidate" :label-width="80" ref="sendmsgForm">
                        <FormItem label="姓名" prop="content">
                            <Input v-model.trim="userName" :maxlength="20" placeholder="请填写姓名" />
                        </FormItem>
                        <FormItem label="手机号" prop="remark">
                            <Input v-model.trim="mobile" :maxlength="11" show-word-limit placeholder="请填写手机号" />
                        </FormItem>
                    </Form>
                </template>
                <template v-slot:toolsbar>
                    <Button type="primary" @click="previewStatusFn(false)" style="margin-right: 10px">关闭</Button>
                    <Button type="primary" @click="saveAddSave">保存</Button>
                </template>
            </LiefengModal>
        </template>
    </LiefengContent>
</template>

<script>
import LiefengContent from "@/components/LiefengContent3"
import LiefengTable from "@/components/LiefengTable"
import LiefengModal from "@/components/LiefengModal"
export default {
    components: {
        LiefengContent,
        LiefengTable,
        LiefengModal,
    },
    data() {
        return {
            sendmsgValidate: {
                userName: [{ required: true, message: "姓名不能为空", trigger: "blur" }],
                mobile: [{ required: true, message: "手机号不能为空", trigger: "blur" }],
            },
            talbeColumns: [
                {
                    title: "姓名",
                    align: "center",
                    minWidth: 100,
                    key: "username",
                },
                {
                    title: "手机号",
                    align: "center",
                    minWidth: 100,
                    key: "mobile",
                },
                {
                    fixed: "right",
                    title: "操作",
                    minWidth: 280,
                    align: "center",
                    render: (h, params) => {
                        return h("div", [
                            h(
                                "Button",
                                {
                                    props: {
                                        type: "info",
                                        size: "small",
                                    },
                                    style: {
                                        marginLeft: "14px",
                                    },
                                    on: {
                                        click: () => {
                                            this.delete(params.row.userId)
                                        },
                                    },
                                },
                                "删除"
                            ),
                        ])
                    },
                },
            ],
            tableData: [],
            page: 1,
            pageSize: 20,
            loading: false,
            total: 0,
            btnLoading: false,
            userName: "",
            mobile: "",
            previewStatus:false
        }
    },
    created() {
        this.getList()
    },
    methods: {
        delete(userId) {
            this.$post("/gateway/api/sysmartscreen/app/terminal/deleteUser", {
                userId,
            }).then(res => {
                if (res && res.code == 200) {
                    this.$Message.success({
                        content: "删除成功",
                        background: true,
                    })
                    this.getList()
                    return
                } else {
                    this.$Message.error({
                        content: "删除失败",
                        background: true,
                    })
                }
            })
        },
        // 点击保存按钮
        saveAddSave() {
            this.$post(
                "/gateway/api/sysmartscreen/app/terminal/userBindTerminal",
                {
                    username: this.username,
                    mobile: this.mobile,
                    terminalIds: [this.$route.query.id],
                },
                { "content-type": "application/json" }
            ).then(res => {
                if (res && res.code == 200) {
                    this.$Message.success({
                        content: "保存成功",
                        background: true,
                    })
                    this.getList()
                    this.previewStatusFn(false)
                } else {
                    this.$Message.error({
                        content: "保存失败",
                        background: true,
                    })
                }
            })
        },
        previewStatusFn(status) {
            this.previewStatus = status
        },
        hadlePageSize(val) {
            this.page = val.page
            this.pageSize = val.pageSize
            this.getList()
        },
        async getList() {
            this.loading = true
            await this.$get("/gateway/api/sysmartscreen/app/terminal/getTerminalByCode", {
                code: this.$route.query.terminalUuid,
            }).then(res => {
                this.loading = false
                this.tableData = res.data.userList
            })
        },
        async syncDeviceAuth() {
            this.btnLoading = true
            await this.$get("/gateway/api/sysmartscreen/pc/terminal/syncDeviceAuth", {
                orgCodeSelf: this.$route.query.orgCodeSelf,
            }).then(res => {
                this.btnLoading = false
                if (res.code == 200) {
                    this.$Message.success({
                        content: "刷新成功",
                        background: true,
                    })
                    return
                } else {
                    this.$Message.error({
                        content: "刷新失败",
                        background: true,
                    })
                    return
                }
            })
        },
    },
}
</script>
