<template>
    <div class="governancenetwork">
        <div class="top-div">
            <!-- <div class="left">农林街党建引领全域服务治理网络</div> -->
            <div class="right" @click="openSet">设置</div>
        </div>
        <div class="title-div">
            <div class="title">党建引领主导多元共建组织联系工作协调机制</div>
        </div>
        <div class="content-div">
            <div class="item" v-for="(item, index) in otherList" :key="index" @click="openFrame(item)">
                <div class="title">{{ item.rootOrgName }}</div>
                <div class="logo"></div>
                <div class="username">{{ item.adminUser || "未知" }}</div>
                <div class="usertype">{{ item.adminType || "未知" }}</div>
            </div>
        </div>

        <LiefengModal title="设置负责人" width="700px" height="700px" :fullscreen="false" :value="analysisStatus" @input="analysisStatusFn" class="analysis">
            <template v-slot:contentarea>
                <div class="set-content">
                    <div class="item" v-for="(item, index) in editList" :key="index">
                        <div class="title">{{ item.rootOrgName }}</div>
                        <div class="input"><Input placeholder="负责人姓名" v-model="item.adminUser" @on-blur="(val) => handleData(val,'adminUser',index)" /></div>
                        <div class="input"><Input placeholder="负责人手机号" v-model="item.adminMobile" @on-blur="(val) => handleData(val,'adminMobile',index)" /></div>
                        <div class="input"><Input placeholder="负责人身份" v-model="item.adminType" @on-blur="(val) => handleData(val,'adminType',index)" /></div>
                    </div>
                </div>
            </template>
            <template v-slot:toolsbar>
                <Button @click="analysisStatusFn(false)" type="info">关闭</Button>
            </template>
        </LiefengModal>
    </div>
</template>

<script>
import LiefengModal from "@/components/LiefengModal"
export default {
    components: { LiefengModal },
    data() {
        return {
            templateList: [],
            otherList: [],
            analysisStatus: false,
            editList: [],
        }
    },
    async created() {
        await this.getTemplateList()
        await this.getByOrgCode()
    },
    methods: {
        handleData(val,name,index){
            this.editList[index][name] = val.target._value

            console.log(this.editList);
            
            this.update(this.editList[index])
        },
        update(data){
            this.$post('/gateway/org/pc/GrassrootsTypeAdminApi/update',{
                ...data
            },{ "Content-Type": "Application/json" }).then(res=>{
                if(res.code == 200){
                    this.$Message.success({
                        content:'修改成功',
                        background:true
                    })
                    this.getByOrgCode()
                    return
                }else{
                    this.$Message.error({
                        content:'修改失败',
                        background:true
                    })
                    return
                }
            })
        },
        analysisStatusFn(status) {
            this.analysisStatus = status
        },
        // 点击设置按钮
        openSet() {
            this.analysisStatusFn(true)
        },
        async getTemplateList() {
            return await this.$get("/gateway/org/pc/grassrootsOrg/selectTemplateList").then(res => {
                if (res.code === "200") {
                    this.templateList = res.dataList?.filter(v => v.orgType != 1 && v.orgType != 3 && v.orgType != 4 && v.orgType != '')
                    // this.templateList = res.dataList
                    console.log(this.templateList)
                }
            })
        },
        async getByOrgCode() {
            return await this.$get("/gateway/org/pc/GrassrootsTypeAdminApi/getByOrgCode", {
                orgCode: window.sessionStorage.getItem("orgCode"),
            }).then(res => {
                this.otherList = this.editList = res.dataList?.filter(v => v.orgType != 1 && v.orgType != 3 && v.orgType != 4 && v.orgType != '').map(item => {
                    var rootOrgName = ""
                    this.templateList.map(items => {
                        if (item.orgType == items.orgType) {
                            rootOrgName = items.rootOrgName
                        }
                    })
                    return {
                        ...item,
                        rootOrgName,
                    }
                }).filter( v => v.rootOrgName && v.rootOrgName != '')
                console.log(this.otherList)
            })
        },
        openFrame(item){
            this.$core.openLayerFrame({
                type: 2,
                title: item.rootOrgName,
                content: `/page#/governancenetworkother?orgType=${item.orgType}&rootOrgName=${item.rootOrgName}&adminType=${item.adminType}&adminUser=${item.adminUser}`,
                area: ["100%", "100%"],
            })
        },
    },
}
</script>

<style lang="less" scoped>
.governancenetwork {
    width: 100vw;
    height: 100vh;
    background-image: url("https://liefeng.oss-cn-shenzhen.aliyuncs.com/PROD/zjsm/20250113/127131452.jpg");
    overflow: hidden;
    .top-div {
        font-size: 28px;
        font-weight: bold;
        margin: 20px;
        display: flex;
        justify-content: space-between;
        .left {
        }
        .right {
            font-size: 20px;
            color: #999;
            cursor: pointer;
            margin-left: auto;
        }
    }
    .title-div {
        text-align: center;
        margin: 20px 0;
        font-size: 32px;
        font-weight: bold;
        .title {
            color: red;
        }
    }
    .content-div {
        margin: 20px;
        display: flex;
        flex-wrap: wrap;
        .item {
            text-align: center;
            width: 20%;
            border: 2px solid #fbe8bf;
            border-radius: 5px;
            margin: 20px;
            background-color: #fff;
            font-size: 18px;
            cursor: pointer;
            .title {
                margin: 10px 0;
            }
            // .logo {
            //     margin: 20px 0;
            // }
            .username {
                margin: 10px 0;
            }
            .usertype{
                font-weight: bold;
                font-size: 20px;
            }
        }
    }
}
.set-content {
    overflow-y: scroll;
    .title {
        width: 160px;
    }
    .input {
        margin-left: 10px;
    }
    .item {
        margin: 10px 0;
        display: flex;
        align-items: center;
    }
}
</style>