<template>
    <LiefengContent>
        <template #title>收货地址经纬度管理</template>
        <template #toolsbarRight>
            <Input v-model.trim="mobile" :maxlength="20" search enter-button="查询" placeholder="请输入手机号码" @on-search="search" style="width: 250px; margin-right: 5px" />
            <Select
                v-model="lngAndLat"
                filterable
                style="width: 300px"
                transfer
                placeholder="待完善经纬度"
                @on-change="hadlePageSize({page: 1,
                pageSize: 20,})"
            >
                <Option value="1">无经纬度</Option>
                <Option value="">全部</Option>
            </Select>
        </template>
        <template #contentArea>
            <LiefengTable
                :talbeColumns="talbeColumns"
                :tableData="tableData"
                :loading="loading"
                :fixTable="true"
                :curPage="page"
                :total="total"
                :pagesizeOpts="[20, 30, 50, 100]"
                :page-size="pageSize"
                @hadlePageSize="hadlePageSize"
            ></LiefengTable>

            <!-- 新增 -->
            <LiefengModal :title="addChangeTitle" :value="addChangeStatus" width="450px" height="120px" @input="addChangeStatusFn">
                <template #contentarea>
                    <Form :rules="addChangeFormValidate" :model="addChangeForm" :label-width="80" ref="addChangeForm">
                        <FormItem label="标签名称" prop="labelName">
                            <Input :maxlength="20" v-model.trim="addChangeForm.labelName"></Input>
                        </FormItem>
                    </Form>
                </template>
                <template #toolsbar>
                    <Button type="info" style="margin-right: 10px" @click="addChangeStatus = false">取消</Button>
                    <Button type="primary" @click="saveAddChange">保存</Button>
                </template>
            </LiefengModal>

            <!-- 关联栏目 -->
            <LiefengModal :title="'关联栏目——' + relationTitle" :value="relationStatus" width="600px" height="100vh - 200px" @input="relationStatusFn">
                <template #contentarea>
                    <div>
                        <Button type="primary" style="margin: 0 10px" @click="allSelectRelation">全选</Button>
                        <Button type="warning" @click="cancelSelectRelation">取消选择</Button>
                    </div>
                    <CheckboxGroup v-model="relationArr" style="margin: 0 10px">
                        <div v-for="item in relationList" :key="item.id">
                            <Checkbox :label="item.columnCode" style="display: block; margin: 10px 0">{{ item.columnName }}</Checkbox>
                            <template v-if="item.child">
                                <div style="margin: 10px 0 10px 2em" v-for="subItem in item.child" :key="subItem.id">
                                    <Checkbox style="display: block" :label="subItem.columnCode">{{ subItem.columnName }}</Checkbox>
                                    <template v-if="subItem.child">
                                        <Checkbox style="display: block; margin: 10px 0 10px 2em" v-for="k in subItem.child" :key="k.id" :label="k.columnCode">{{ k.columnName }}</Checkbox>
                                    </template>
                                </div>
                            </template>
                        </div>
                    </CheckboxGroup>
                </template>
                <template #toolsbar>
                    <Button type="info" style="margin-right: 10px" @click="relationStatusFn(false)">取消</Button>
                    <Button type="primary" @click="saveRelation">保存</Button>
                </template>
            </LiefengModal>
        </template>
    </LiefengContent>
</template>

<script>
//@route('/tagindex');
import LiefengContent from "@/components/LiefengContent3"
import LiefengModal from "@/components/LiefengModal"
import LiefengTable from "@/components/LiefengTable"
export default {
    data() {
        return {
            talbeColumns: [
                {
                    align: "center",
                    title: "收货地址百度经纬度",
                    minWidth: 200,
                    render: (h, params) => {
                        return h("div", [
                            h("Input", {
                                props: {
                                    value: params.row.lng && params.row.lng != "" ? params.row.lng + "," + params.row.lat : "",
                                },
                                on: {
                                    "on-change": e => {
                                        this.changeValue(e.target.value, params.row)
                                    },
                                },
                            }),
                        ])
                    },
                },
                {
                    title: "收货地址",
                    key: "labelName",
                    align: "center",
                    minWidth: 200,
                    render: (h, params) => {
                        return h("div", {}, params.row.address + params.row.addr || "")
                    },
                },
                {
                    title: "收货人",
                    key: "consignee",
                    align: "center",
                    minWidth: 200,
                },
                {
                    title: "手机号",
                    key: "mobile",
                    align: "center",
                    minWidth: 200,
                },
                {
                    title: "创建时间",
                    align: "center",
                    minWidth: 200,
                    render: (h, params) => {
                        return h("div", {}, params.row.gmtCreate ? this.$core.formatDate(new Date(params.row.gmtCreate), "yyyy-MM-dd hh:mm:ss") : "")
                    },
                },

                {
                    title: "操作",
                    width: 200,
                    align: "center",
                    render: (h, params) => {
                        return h("div", [
                            h(
                                "Button",
                                {
                                    props: {
                                        type: "success",
                                        size: "small",
                                    },
                                    style: {
                                        marginRight: "10px",
                                    },
                                    on: {
                                        click: () => {
                                            this.$get("/gateway/apps/basic/api/base/addr/getConsigneeAddrById", {
                                                id: params.row.id,
                                            }).then(res => {
                                                if (res && res.data && res.code == 200) {
                                                    this.tableData.splice(params.row._index, 1, res.data)
                                                }
                                            })
                                        },
                                    },
                                },
                                "查看"
                            ),
                        ])
                    },
                },
            ],
            tableData: [],
            loading: false,
            page: 1,
            total: 0,
            pageSize: 20,
            mobile: "",
            lngAndLat: "1",
        }
    },
    methods: {
        changeValue(val, row) {
            let data = {}
            let newRow = JSON.parse(JSON.stringify(row))
            data.id = newRow.id
            if (val && val != "" && val.indexOf(",") > -1) {
                var lnglat = val.split(",")
                data.lng = lnglat[0]
                data.lat = lnglat[1]
            }
            this.$core.debounce(
                () => {
                    this.updateRow(data)
                },
                "timer",
                1000
            )
        },
        async updateRow(val) {
            await this.$post("/gateway/apps/basic/api/base/addr/updateLngAndLat", val).then(res => {
                if (res.code == 200) {
                    this.$Message.success({
                        content: "操作成功",
                        background: true,
                    })
                    return
                } else {
                    this.$Message.error({
                        content: res.desc,
                        background: true,
                    })
                    return
                }
            })
        },
        search() {
            this.hadlePageSize({
                page: 1,
                pageSize: this.pageSize,
            })
        },
        hadlePageSize(obj) {
            this.loading = true
            this.$get("/gateway/apps/basic/api/base/addr/getAddrPage", {
                mobile: this.mobile,
                page: obj.page,
                pageSize: obj.pageSize,
                lngAndLat: this.lngAndLat || '',
            })
                .then(res => {
                    if (res.code == 200 && res.dataList) {
                        this.tableData = res.dataList
                        this.page = res.currentPage
                        this.pageSize = res.pageSize
                        this.total = res.maxCount
                        this.loading = false
                    } else {
                        this.loading = false
                        this.$Message.error({
                            background: true,
                            content: res.desc,
                        })
                    }
                })
                .catch(err => {
                    this.loading = false
                    console.log(err)
                    this.$Message.error({
                        background: true,
                        content: "获取数据失败，请联系管理员处理",
                    })
                })
        },
    },
    created() {
        this.hadlePageSize({
            page: this.page,
            pageSize: this.pageSize,
        })
    },
    components: {
        LiefengContent,
        LiefengModal,
        LiefengTable,
    },
}
</script>
    
<style scoped lang='less'>
/deep/.ivu-transfer {
    .ivu-transfer-list {
        width: 40%;
        height: calc(100vh - 200px);
    }
}
</style>