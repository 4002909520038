

<template>
    <div class="detail-div" v-if="showForm">
        <div class="equiment-title">基本信息</div>
         <div class="buy-div">
            <div class="item">
                <span>订单标题:</span>
                <span class="text">{{ detailMessage.orderTitle || "暂无" }}</span>
            </div>
            <div class="item">
                <span>配送方式:</span>
                <span class="text">{{ detailMessage.shippingType == "1" ? "堂食" : detailMessage.shippingType == "2" ? "打包外带" : detailMessage.shippingType == "3" ? "配送" : "普通订单" || "未知" }}</span>
            </div>
             <div class="item">
                <span>订单配送日期:</span>
                <span class="text">{{ detailMessage.orderShippingDate ? this.$core.formatDate(new Date(detailMessage.orderShippingDate), "yyyy-MM-dd hh:mm:ss") : "暂无" }}</span>
            </div>
            <div class="item">
                <span>订单配送时间:</span>
                <span class="text">{{ detailMessage.orderShippingTime ? detailMessage.orderShippingTime : "暂无" }}</span>
            </div>

             <div class="item">
                <span>订单配送时间（分）:</span>
                <span class="text">{{ detailMessage.promiseDeliveryTime ? detailMessage.promiseDeliveryTime : "" }}</span>
            </div>
            <div class="item">
                <span>配送总金额（元）:</span>
                <span class="text">{{ detailMessage.thirdShippingTotalPrice ? detailMessage.thirdShippingTotalPrice/100 : 0}}</span>
            </div>
            <div class="item">
                <span>配送距离（米）:</span>
                <span class="text">{{ detailMessage.deliveryDistanceMeter ? detailMessage.deliveryDistanceMeter : 0}}</span>
            </div>

             <div class="item">
                <span>起送时间:</span>
                <span class="text">{{ detailMessage.startTime ? this.$core.formatDate(new Date(detailMessage.startTime), "hh:mm:ss") : "暂无" }}</span>
            </div>
             <div class="item">
                <span>预计送达时间:</span>
                <span class="text">{{ detailMessage.expectTime ? this.$core.formatDate(new Date(detailMessage.expectTime), "hh:mm:ss") : "暂无" }}</span>
            </div>
            <div class="item">
                <span>订单商品金额（元）:</span>
                <span class="text">{{ detailMessage.orderGoodsAmount ? detailMessage.orderGoodsAmount/100 : 0}}</span>
            </div>
            <div class="item">
                <span>订单打包金额（元）:</span>
                <span class="text">{{ detailMessage.orderPackageAmount ? detailMessage.orderPackageAmount/100 : 0}}</span>
            </div>
            <div class="item">
                <span>订单配送金额（元）:</span>
                <span class="text">{{ detailMessage.orderShippingAmount ? detailMessage.orderShippingAmount/100 : 0}}</span>
            </div>
            <div class="item">
                <span>订单总金额（元）:</span>
                <span class="text">{{ detailMessage.orderTotalAmount ? detailMessage.orderTotalAmount/100 : 0}}</span>
            </div>
            <div class="item">
                <span>订单手续费（元）:</span>
                <span class="text">{{ detailMessage.orderChargeAmount ? detailMessage.orderChargeAmount/100 : 0}}</span>
            </div>
            <div class="item">
                <span>订单重量:</span>
                <span class="text">{{ detailMessage.weight ? detailMessage.weight : 0}}</span>
            </div>

            <div class="item">
                <span>订单类型:</span>
                <span class="text">{{detailMessage.deliveryType == 0 ? '预约送达单' : detailMessage.deliveryType == 1 ? '立即单' : detailMessage.deliveryType == 3 ? '预约上门单' : ''}}</span>
            </div>
        </div>

        <div class="equiment-title">下单用户信息</div>
        <div class="base-div">
            <div class="left">
                <span>用户姓名:</span>
                <span class="text">{{ detailMessage.orderUserInfo.userName || "未知" }}</span>
                <span class="title">用户手机号:</span>
                <span class="text">{{ detailMessage.orderUserInfo.userMobile || "暂无" }}</span>
                <span class="title">用户配送地址:</span>
                <span class="text">{{ detailMessage.orderUserInfo.consignee || "暂无" }}</span>
            </div>
        </div>
        <div class="equiment-title">店铺信息</div>
        <div class="base-div">
            <div class="left">
                <span class="title">店铺名称:</span>
                <span class="text">{{ detailMessage.supplier.supplierName || "暂无" }}</span>
                <span class="title">店铺联系电话:</span>
                <span class="text">{{ detailMessage.supplier.mobile || "暂无" }}</span>
            </div>
        </div>

        <div class="equiment-title">配送商品信息</div>
        <div class="buy-div" v-for="(item, index) in detailMessage.products" :key="index">
            <div class="item">
                <span>商品名称:</span>
                <span class="text">{{ item.productName }}</span>
            </div>
            <div class="item">
                <span>商品总重量:</span>
                <span class="text">{{ item.weightTotal }}</span>
            </div>
            <div class="item">
                <span>购买数量:</span>
                <span class="text">{{ item.buyNum }}</span>
            </div>
            <div class="item">
                <span>商品单个重量:</span>
                <span class="text">{{ item.weight }}</span>
            </div>
        </div>
    </div>
</template>

<script>
import LiefengModal from "@/components/LiefengModal"
export default {
    props: ["showNum", "recordId"],
    components: { LiefengModal },
    data() {
        return {
            detailMessage: {},
            backTime: "",

            reasonStatus: false,
            reason: "",
            showForm: true,
        }
    },
    methods: {
        // 接口部分
        getDetail() {
            this.$Message.loading({
                content: "正在加载数据...",
            })
            this.showForm = false
            this.$nextTick(() => {
                this.showForm = true
            })
            this.detailMessage = {}
            this.backTime = "暂无"
            this.$post("/gateway/apps/o2o/api/o2o/order/shippingOrderToThirdPre", {
                orderId: this.recordId,
            }).then(res => {
                this.$Message.destroy()
                if (res.code == 200) {
                    this.detailMessage = res.data
                } else {
                    this.$Message.error({
                        content: "获取详情失败",
                        background: true,
                    })
                    return
                }
            })
        },
    },
    watch: {
        showNum() {
            this.getDetail()
        },
    },
}
</script>

<style lang="less" scoped>
.detail-div {
    .equiment-title {
        position: relative;
        padding-left: 20px;
        margin: 10px 0;
        &::after {
            position: absolute;
            content: "";
            top: 0;
            left: 0;
            width: 5px;
            height: 20px;
            background-color: #2db7f5;
        }
    }
    .base-div {
        display: flex;
        justify-content: space-between;
        margin: 10px 0;
        .left {
            .title {
                margin-left: 20px;
            }
            .text {
                margin-left: 10px;
            }
        }
    }
    .equi-div {
        display: flex;
        border: 1px solid #cccccc;
        width: 100%;
        padding: 10px;
        .left {
            width: 20%;
            margin-right: 10px;
            .image {
                border-radius: 5px;
                width: 150px;
                height: 150px;
            }
        }
        .right {
            width: 80%;
            .form-div {
                display: flex;
                .item {
                    text-align: center;
                    width: 25%;
                    word-wrap: break-word;
                    .top {
                        font-weight: bold;
                    }
                }
            }
        }
    }
    .container-div {
        width: 100%;
        .title {
            height: 40px;
            line-height: 40px;
            display: flex;
            text-align: center;
            font-weight: bold;
            .left {
                width: 50%;
            }
            .right {
                width: 50%;
            }
        }
        .item {
            display: flex;
            text-align: center;
            margin: 10px 0;
            border-top: 1px dashed #cccccc;
            padding: 10px 0;
            .left {
                width: 50%;
            }
            .right {
                width: 50%;
                .img {
                    width: 150px;
                    height: 150px;
                }
                .vide {
                    width: 150px;
                    height: 150px;
                }
            }
        }
    }
    .buy-div {
        .item {
            .text {
                margin-left: 10px;
            }
        }
    }
}
</style>